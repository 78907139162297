@font-face{
  font-display:swap;

  font-family:"Lust";

  font-style:normal;

  font-weight:400;

  src:url("../assets/fonts/lust.woff2") format("woff2"),url("../assets/fonts/lust.woff") format("woff")
}

.prose.prose-sm.sm\:prose{
  font-family:Lora, serif;
  font-weight:500;
  font-size:0.875rem;
  line-height:1.25rem;
  max-width:-webkit-max-content;
  max-width:-moz-max-content;
  max-width:max-content;
  font-size:1rem
}

@media screen and (max-width: 768px){
  .prose.prose-sm.sm\:prose{
    font-size:1rem
  }
}

.prose.prose-sm.sm\:prose ul li{
  padding:0px
}

.prose blockquote{
  --tw-text-opacity:1 !important;
  color:rgba(16, 161, 217, var(--tw-text-opacity)) !important
}

.prose blockquote p, .prose blockquote span, .prose blockquote strong, .prose blockquote em{
  --tw-text-opacity:1 !important;
  color:rgba(16, 161, 217, var(--tw-text-opacity)) !important
}

.form-single .title{
  font-weight:600;
  font-size:0.75rem;
  line-height:1rem;
  padding-top:1rem;
  padding-bottom:1rem;
  padding-top:1.5rem;
  --tw-text-opacity:1;
  color:rgba(63, 63, 70, var(--tw-text-opacity));
  text-transform:uppercase
}

.form-single .terms{
  font-size:0.75rem;
  line-height:1rem;
  line-height:1.25;
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  --tw-text-opacity:1;
  color:rgba(161, 161, 170, var(--tw-text-opacity))
}

.form-single .terms a{
  --tw-text-opacity:1;
  color:rgba(249, 115, 22, var(--tw-text-opacity));
  text-decoration:underline
}

.form-single .info{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
      flex-wrap:wrap;
  -webkit-box-pack:start;
      -ms-flex-pack:start;
          justify-content:flex-start
}

.form-single .info span{
  width:100%
}

@media (min-width: 768px){
  .form-single .info span{
    width:66.666667%
  }
}

@media (min-width: 1024px){
  .form-single .info span{
    width:100%
  }
}

@media (min-width: 1280px){
  .form-single .info span{
    width:66.666667%
  }
}

.form-single .info span .wpcf7-not-valid-tip{
  display:none
}

.form-single .info .ajax-loader{
  display:none
}

.form-single .info #email{
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity:1;
  border-color:rgba(194, 65, 12, var(--tw-border-opacity));
  border-radius:0.375rem;
  border-width:1px
}

.form-single .info #email:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

.form-single .info #email{
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  padding-left:1rem;
  padding-right:1rem
}

.form-single .info #email:focus{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  -webkit-box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 rgba(0,0,0,0);
          box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 rgba(0,0,0,0);
  -webkit-box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0,0,0,0));
          box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0,0,0,0));
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(194, 65, 12, var(--tw-ring-opacity));
  --tw-ring-opacity:0.4
}

.form-single .info #email{
  width:100%;
  -webkit-transition-property:background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform;
  transition-property:background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform;
  transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-box-shadow, -webkit-transform;
  -webkit-transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration:150ms;
          transition-duration:150ms;
  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration:300ms;
          transition-duration:300ms
}

@media (min-width: 768px){
  .form-single .info #email{
    border-top-right-radius:0px;
    border-bottom-right-radius:0px;
    border-top-left-radius:0.375rem;
    border-bottom-left-radius:0.375rem
  }
}

@media (min-width: 1024px){
  .form-single .info #email{
    border-top-right-radius:0.375rem;
    border-bottom-right-radius:0.375rem
  }
}

@media (min-width: 1280px){
  .form-single .info #email{
    border-top-right-radius:0px;
    border-bottom-right-radius:0px
  }
}

.form-single .info #email{
  -webkit-appearance:none
}

.form-single .info #submit{
  --tw-bg-opacity:1;
  background-color:rgba(194, 65, 12, var(--tw-bg-opacity));
  --tw-border-opacity:1;
  border-color:rgba(212, 212, 216, var(--tw-border-opacity));
  border-radius:0.375rem;
  cursor:pointer;
  font-weight:500;
  font-size:0.75rem;
  line-height:1rem;
  margin-top:0.5rem;
  padding-top:0.75rem;
  padding-bottom:0.75rem;
  padding-left:0.75rem;
  padding-right:0.75rem;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity));
  width:100%
}

@media (min-width: 768px){
  .form-single .info #submit{
    border-top-left-radius:0px;
    border-bottom-left-radius:0px;
    border-top-right-radius:0.375rem;
    border-bottom-right-radius:0.375rem;
    margin-top:0px;
    width:33.333333%
  }
}

@media (min-width: 1024px){
  .form-single .info #submit{
    border-top-left-radius:0.375rem;
    border-bottom-left-radius:0.375rem;
    margin-top:0.5rem;
    width:100%
  }
}

@media (min-width: 1280px){
  .form-single .info #submit{
    border-top-left-radius:0px;
    border-bottom-left-radius:0px;
    margin-top:0px;
    width:33.333333%
  }
}

.form-single .wpcf7 form.invalid>.wpcf7-response-output{
  background-color:#dc2626;
  border-color:#dc2626 !important;
  color:#fff;
  padding:.5rem 1rem .5rem 1rem !important;
  border-radius:0.5rem;
  border-style:none;
  font-weight:500;
  font-size:0.875rem;
  line-height:1.25rem;
  margin:1rem;
  position:absolute;
  right:0px;
  bottom:0px;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity));
  z-index:60
}

@media (min-width: 1024px){
  .form-single .wpcf7 form.invalid>.wpcf7-response-output{
    font-size:1rem;
    line-height:1.5rem
  }
}

.form-single .wpcf7 form.invalid>.wpcf7-response-output{
  font-size:1rem
}

@media screen and (max-width: 768px){
  .form-single .wpcf7 form.invalid>.wpcf7-response-output{
    font-size:1rem
  }
}

.form-single .wpcf7 form.invalid>.wpcf7-response-output{
  -webkit-animation:cssAnimation 5s forwards;
  animation:cssAnimation 5s forwards;
  -webkit-backdrop-filter:blur(7px);
          backdrop-filter:blur(7px)
}

.form-single .wpcf7-response-output,.form-single .wpcf7 form.unaccepted{
  background-color:#ea580c;
  border-color:#ea580c !important;
  color:#fff;
  padding:.5rem 1rem .5rem 1rem !important
}

.form-single .wpcf7-response-output, .form-single .wpcf7 form.unaccepted{
  border-radius:0.5rem;
  border-style:none;
  font-weight:500;
  font-size:0.875rem;
  line-height:1.25rem;
  margin:1rem;
  position:absolute;
  right:0px;
  bottom:0px;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity));
  z-index:60
}

@media (min-width: 1024px){
  .form-single .wpcf7-response-output, .form-single .wpcf7 form.unaccepted{
    font-size:1rem;
    line-height:1.5rem
  }
}

.form-single .wpcf7-response-output,.form-single .wpcf7 form.unaccepted{
  font-size:1rem
}

@media screen and (max-width: 768px){
  .form-single .wpcf7-response-output,.form-single .wpcf7 form.unaccepted{
    font-size:1rem
  }
}

.form-single .wpcf7-response-output,.form-single .wpcf7 form.unaccepted{
  -webkit-animation:cssAnimation 5s forwards;
  animation:cssAnimation 5s forwards;
  -webkit-backdrop-filter:blur(7px);
          backdrop-filter:blur(7px)
}

@keyframes cssAnimation{
  0%{
    opacity:1
  }

  90%{
    opacity:1
  }

  100%{
    opacity:0
  }
}

@-webkit-keyframes cssAnimation{
  0%{
    opacity:1
  }

  90%{
    opacity:1
  }

  100%{
    opacity:0
  }
}

.mainHeader{
  display:unset
}

.text-shadow-base{
  text-shadow:0 2px 4px rgba(0,0,0,.5)
}

.breadcrumb_last{
  font-weight:700
}

.post-main iframe{
  width:100%;
  min-height:630px
}

.post-password-form{
  color:#3f3f46;
  max-width:65ch
}

.post-password-form [class~="lead"]{
  color:#52525b;
  font-size:1.25em;
  line-height:1.6;
  margin-top:1.2em;
  margin-bottom:1.2em
}

.post-password-form a{
  color:#18181b;
  text-decoration:underline;
  font-weight:500
}

.post-password-form strong{
  color:#18181b;
  font-weight:600
}

.post-password-form ol[type="A"]{
  --list-counter-style:upper-alpha
}

.post-password-form ol[type="a"]{
  --list-counter-style:lower-alpha
}

.post-password-form ol[type="A s"]{
  --list-counter-style:upper-alpha
}

.post-password-form ol[type="a s"]{
  --list-counter-style:lower-alpha
}

.post-password-form ol[type="I"]{
  --list-counter-style:upper-roman
}

.post-password-form ol[type="i"]{
  --list-counter-style:lower-roman
}

.post-password-form ol[type="I s"]{
  --list-counter-style:upper-roman
}

.post-password-form ol[type="i s"]{
  --list-counter-style:lower-roman
}

.post-password-form ol[type="1"]{
  --list-counter-style:decimal
}

.post-password-form ol > li{
  position:relative;
  padding-left:1.75em
}

.post-password-form ol > li::before{
  content:counter(list-item, decimal) ".";
  content:counter(list-item, var(--list-counter-style, decimal)) ".";
  position:absolute;
  font-weight:400;
  color:#71717a;
  left:0
}

.post-password-form ul > li{
  position:relative;
  padding-left:1.75em
}

.post-password-form ul > li::before{
  content:"";
  position:absolute;
  background-color:#d4d4d8;
  border-radius:50%;
  width:0.375em;
  height:0.375em;
  top:calc(0.875em - 0.1875em);
  left:0.25em
}

.post-password-form hr{
  border-color:#e4e4e7;
  border-top-width:1px;
  margin-top:3em;
  margin-bottom:3em
}

.post-password-form blockquote{
  font-weight:500;
  font-style:italic;
  color:#18181b;
  border-left-width:0.25rem;
  border-left-color:#e4e4e7;
  quotes:"\201C""\201D""\2018""\2019";
  margin-top:1.6em;
  margin-bottom:1.6em;
  padding-left:1em
}

.post-password-form blockquote p:first-of-type::before{
  content:open-quote
}

.post-password-form blockquote p:last-of-type::after{
  content:close-quote
}

.post-password-form h1{
  color:#18181b;
  font-weight:800;
  font-size:2.25em;
  margin-top:0;
  margin-bottom:0.8888889em;
  line-height:1.1111111
}

.post-password-form h2{
  color:#18181b;
  font-weight:700;
  font-size:1.5em;
  margin-top:2em;
  margin-bottom:1em;
  line-height:1.3333333
}

.post-password-form h3{
  color:#18181b;
  font-weight:600;
  font-size:1.25em;
  margin-top:1.6em;
  margin-bottom:0.6em;
  line-height:1.6
}

.post-password-form h4{
  color:#18181b;
  font-weight:600;
  margin-top:1.5em;
  margin-bottom:0.5em;
  line-height:1.5
}

.post-password-form figure figcaption{
  color:#71717a;
  font-size:0.875em;
  line-height:1.4285714;
  margin-top:0.8571429em
}

.post-password-form code{
  color:#18181b;
  font-weight:600;
  font-size:0.875em
}

.post-password-form code::before{
  content:"`"
}

.post-password-form code::after{
  content:"`"
}

.post-password-form a code{
  color:#18181b
}

.post-password-form pre{
  color:#e4e4e7;
  background-color:#27272a;
  overflow-x:auto;
  font-size:0.875em;
  line-height:1.7142857;
  margin-top:1.7142857em;
  margin-bottom:1.7142857em;
  border-radius:0.375rem;
  padding-top:0.8571429em;
  padding-right:1.1428571em;
  padding-bottom:0.8571429em;
  padding-left:1.1428571em
}

.post-password-form pre code{
  background-color:transparent;
  border-width:0;
  border-radius:0;
  padding:0;
  font-weight:400;
  color:inherit;
  font-size:inherit;
  font-family:inherit;
  line-height:inherit
}

.post-password-form pre code::before{
  content:none
}

.post-password-form pre code::after{
  content:none
}

.post-password-form table{
  width:100%;
  table-layout:auto;
  text-align:left;
  margin-top:2em;
  margin-bottom:2em;
  font-size:0.875em;
  line-height:1.7142857
}

.post-password-form thead{
  color:#18181b;
  font-weight:600;
  border-bottom-width:1px;
  border-bottom-color:#d4d4d8
}

.post-password-form thead th{
  vertical-align:bottom;
  padding-right:0.5714286em;
  padding-bottom:0.5714286em;
  padding-left:0.5714286em
}

.post-password-form tbody tr{
  border-bottom-width:1px;
  border-bottom-color:#e4e4e7
}

.post-password-form tbody tr:last-child{
  border-bottom-width:0
}

.post-password-form tbody td{
  vertical-align:top;
  padding-top:0.5714286em;
  padding-right:0.5714286em;
  padding-bottom:0.5714286em;
  padding-left:0.5714286em
}

.post-password-form{
  font-size:1rem;
  line-height:1.75
}

.post-password-form p{
  margin-top:1.25em;
  margin-bottom:1.25em
}

.post-password-form img{
  margin-top:2em;
  margin-bottom:2em
}

.post-password-form video{
  margin-top:2em;
  margin-bottom:2em
}

.post-password-form figure{
  margin-top:2em;
  margin-bottom:2em
}

.post-password-form figure > *{
  margin-top:0;
  margin-bottom:0
}

.post-password-form h2 code{
  font-size:0.875em
}

.post-password-form h3 code{
  font-size:0.9em
}

.post-password-form ol{
  margin-top:1.25em;
  margin-bottom:1.25em
}

.post-password-form ul{
  margin-top:1.25em;
  margin-bottom:1.25em
}

.post-password-form li{
  margin-top:0.5em;
  margin-bottom:0.5em
}

.post-password-form > ul > li p{
  margin-top:0.75em;
  margin-bottom:0.75em
}

.post-password-form > ul > li > *:first-child{
  margin-top:1.25em
}

.post-password-form > ul > li > *:last-child{
  margin-bottom:1.25em
}

.post-password-form > ol > li > *:first-child{
  margin-top:1.25em
}

.post-password-form > ol > li > *:last-child{
  margin-bottom:1.25em
}

.post-password-form ul ul, .post-password-form ul ol, .post-password-form ol ul, .post-password-form ol ol{
  margin-top:0.75em;
  margin-bottom:0.75em
}

.prose ul ul, .prose ul ol, .prose ol ul, .prose ol ol{
  margin-top:0.75em;
  margin-bottom:0.75em;
  margin-top:0.75em;
  margin-bottom:0.75em;
  margin-top:0.75em;
  margin-bottom:0.75em
}

.post-password-form hr + *{
  margin-top:0
}

.post-password-form h2 + *{
  margin-top:0
}

.post-password-form h3 + *{
  margin-top:0
}

.post-password-form h4 + *{
  margin-top:0
}

.post-password-form thead th:first-child{
  padding-left:0
}

.post-password-form thead th:last-child{
  padding-right:0
}

.post-password-form tbody td:first-child{
  padding-left:0
}

.post-password-form tbody td:last-child{
  padding-right:0
}

.post-password-form > :first-child{
  margin-top:0
}

.post-password-form > :last-child{
  margin-bottom:0
}

.post-password-form{
  margin-left:1.5rem;
  margin-right:1.5rem;
  margin-top:2rem;
  margin-bottom:2rem;
  max-width:80rem
}

.post-password-form.prose-sm.sm\:prose{
  font-family:Lora, serif;
  font-weight:500;
  font-size:0.875rem;
  line-height:1.25rem;
  max-width:-webkit-max-content;
  max-width:-moz-max-content;
  max-width:max-content;
  font-size:1rem
}

@media screen and (max-width: 768px){
  .post-password-form.prose-sm.sm\:prose{
    font-size:1rem
  }
}

.post-password-form.prose-sm.sm\:prose ul li{
  padding:0px
}

.post-password-form blockquote{
  --tw-text-opacity:1 !important;
  color:rgba(16, 161, 217, var(--tw-text-opacity)) !important
}

.post-password-form blockquote p, .post-password-form blockquote span, .post-password-form blockquote strong, .post-password-form blockquote em{
  --tw-text-opacity:1 !important;
  color:rgba(16, 161, 217, var(--tw-text-opacity)) !important
}

.prose blockquote p, .prose blockquote span, .prose blockquote strong, .prose blockquote em{
  --tw-text-opacity:1 !important;
  color:rgba(16, 161, 217, var(--tw-text-opacity)) !important
}

.prose blockquote p, .prose blockquote span, .prose blockquote strong, .prose blockquote em{
  --tw-text-opacity:1 !important;
  color:rgba(16, 161, 217, var(--tw-text-opacity)) !important
}

.prose blockquote p, .prose blockquote span, .prose blockquote strong, .prose blockquote em{
  --tw-text-opacity:1 !important;
  color:rgba(16, 161, 217, var(--tw-text-opacity)) !important
}

[is=fusion-wysiwyg] .post-password-form{
  max-width:100% !important;
  font-family:Lora, serif;
  font-weight:500;
  font-size:1.125rem
}

[is=fusion-wysiwyg] .post-password-form ul li{
  padding:0px
}

[is=fusion-wysiwyg] .post-password-form .figure{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-align:start;
      -ms-flex-align:start;
          align-items:flex-start
}

@media (min-width: 768px){
  [is=fusion-wysiwyg] .post-password-form .figure{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center
  }
}

[is=fusion-child-ten] .post-password-form{
  max-width:100% !important;
  font-family:Lora, serif;
  font-weight:500;
  font-size:1.125rem
}

[is=fusion-child-ten] .post-password-form ul li{
  padding:0px
}

[is=fusion-child-ten] .post-password-form .figure{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-align:start;
      -ms-flex-align:start;
          align-items:flex-start
}

@media (min-width: 768px){
  [is=fusion-child-ten] .post-password-form .figure{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center
  }
}

.post-password-form input[name=post_password]{
  --tw-border-opacity:1;
  border-color:rgba(0, 0, 0, var(--tw-border-opacity));
  border-width:1px
}

.post-password-form input[type=submit] > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(1rem * var(--tw-space-x-reverse));
  margin-left:calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

.post-password-form input[type=submit]{
  --tw-bg-opacity:1;
  background-color:rgba(16, 161, 217, var(--tw-bg-opacity));
  border-radius:0.25rem;
  font-weight:500;
  height:2rem;
  font-size:0.875rem;
  line-height:1.25rem;
  padding-left:0.75rem;
  padding-right:0.75rem;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity));
  font-size:1rem
}

@media screen and (max-width: 768px){
  .post-password-form input[type=submit]{
    font-size:1rem
  }
}

.text-sm{
  font-size:1rem !important
}

@media screen and (max-width: 768px){
  .text-sm{
    font-size:1rem
  }

  .text-base{
    font-size:1.125rem
  }
}

.quantity-input-woocommerce label.screen-reader-text{
  -webkit-clip-path:none;
          clip-path:none;
  display:block !important;
  font-weight:700 !important;
  height:100% !important;
  margin-left:0px !important;
  margin-right:0px !important;
  margin-top:1rem !important;
  margin-bottom:1rem !important;
  position:relative !important;
  --tw-text-opacity:1 !important;
  color:rgba(161, 161, 170, var(--tw-text-opacity)) !important;
  text-transform:uppercase !important;
  width:100% !important
}

.quantity-input-woocommerce input.qty{
  --tw-border-opacity:1;
  border-color:rgba(228, 228, 231, var(--tw-border-opacity))
}

.quantity-input-woocommerce input.qty:focus{
  --tw-border-opacity:1;
  border-color:rgba(147, 197, 253, var(--tw-border-opacity))
}

.quantity-input-woocommerce input.qty{
  border-radius:0.375rem;
  border-width:1px;
  display:-webkit-inline-box;
  display:-ms-inline-flexbox;
  display:inline-flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  font-weight:600;
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  padding-left:1rem;
  padding-right:1rem
}

.quantity-input-woocommerce input.qty:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(147, 197, 253, var(--tw-ring-opacity))
}

.quantity-input-woocommerce input.qty{
  --tw-text-opacity:1;
  color:rgba(113, 113, 122, var(--tw-text-opacity))
}

.single-product .price .woocommerce-Price-amount{
  display:inline-block;
  font-weight:700;
  font-size:1.5rem;
  line-height:2rem;
  --tw-text-opacity:1;
  color:rgba(97, 202, 242, var(--tw-text-opacity))
}

#tab-description{
  color:#3f3f46;
  max-width:65ch
}

#tab-description [class~="lead"]{
  color:#52525b;
  font-size:1.25em;
  line-height:1.6;
  margin-top:1.2em;
  margin-bottom:1.2em
}

#tab-description a{
  color:#18181b;
  text-decoration:underline;
  font-weight:500
}

#tab-description strong{
  color:#18181b;
  font-weight:600
}

#tab-description ol[type="A"]{
  --list-counter-style:upper-alpha
}

#tab-description ol[type="a"]{
  --list-counter-style:lower-alpha
}

#tab-description ol[type="A s"]{
  --list-counter-style:upper-alpha
}

#tab-description ol[type="a s"]{
  --list-counter-style:lower-alpha
}

#tab-description ol[type="I"]{
  --list-counter-style:upper-roman
}

#tab-description ol[type="i"]{
  --list-counter-style:lower-roman
}

#tab-description ol[type="I s"]{
  --list-counter-style:upper-roman
}

#tab-description ol[type="i s"]{
  --list-counter-style:lower-roman
}

#tab-description ol[type="1"]{
  --list-counter-style:decimal
}

#tab-description ol > li{
  position:relative;
  padding-left:1.75em
}

#tab-description ol > li::before{
  content:counter(list-item, decimal) ".";
  content:counter(list-item, var(--list-counter-style, decimal)) ".";
  position:absolute;
  font-weight:400;
  color:#71717a;
  left:0
}

#tab-description ul > li{
  position:relative;
  padding-left:1.75em
}

#tab-description ul > li::before{
  content:"";
  position:absolute;
  background-color:#d4d4d8;
  border-radius:50%;
  width:0.375em;
  height:0.375em;
  top:calc(0.875em - 0.1875em);
  left:0.25em
}

#tab-description hr{
  border-color:#e4e4e7;
  border-top-width:1px;
  margin-top:3em;
  margin-bottom:3em
}

#tab-description blockquote{
  font-weight:500;
  font-style:italic;
  color:#18181b;
  border-left-width:0.25rem;
  border-left-color:#e4e4e7;
  quotes:"\201C""\201D""\2018""\2019";
  margin-top:1.6em;
  margin-bottom:1.6em;
  padding-left:1em
}

#tab-description blockquote p:first-of-type::before{
  content:open-quote
}

#tab-description blockquote p:last-of-type::after{
  content:close-quote
}

#tab-description h1{
  color:#18181b;
  font-weight:800;
  font-size:2.25em;
  margin-top:0;
  margin-bottom:0.8888889em;
  line-height:1.1111111
}

#tab-description h2{
  color:#18181b;
  font-weight:700;
  font-size:1.5em;
  margin-top:2em;
  margin-bottom:1em;
  line-height:1.3333333
}

#tab-description h3{
  color:#18181b;
  font-weight:600;
  font-size:1.25em;
  margin-top:1.6em;
  margin-bottom:0.6em;
  line-height:1.6
}

#tab-description h4{
  color:#18181b;
  font-weight:600;
  margin-top:1.5em;
  margin-bottom:0.5em;
  line-height:1.5
}

#tab-description figure figcaption{
  color:#71717a;
  font-size:0.875em;
  line-height:1.4285714;
  margin-top:0.8571429em
}

#tab-description code{
  color:#18181b;
  font-weight:600;
  font-size:0.875em
}

#tab-description code::before{
  content:"`"
}

#tab-description code::after{
  content:"`"
}

#tab-description a code{
  color:#18181b
}

#tab-description pre{
  color:#e4e4e7;
  background-color:#27272a;
  overflow-x:auto;
  font-size:0.875em;
  line-height:1.7142857;
  margin-top:1.7142857em;
  margin-bottom:1.7142857em;
  border-radius:0.375rem;
  padding-top:0.8571429em;
  padding-right:1.1428571em;
  padding-bottom:0.8571429em;
  padding-left:1.1428571em
}

#tab-description pre code{
  background-color:transparent;
  border-width:0;
  border-radius:0;
  padding:0;
  font-weight:400;
  color:inherit;
  font-size:inherit;
  font-family:inherit;
  line-height:inherit
}

#tab-description pre code::before{
  content:none
}

#tab-description pre code::after{
  content:none
}

#tab-description table{
  width:100%;
  table-layout:auto;
  text-align:left;
  margin-top:2em;
  margin-bottom:2em;
  font-size:0.875em;
  line-height:1.7142857
}

#tab-description thead{
  color:#18181b;
  font-weight:600;
  border-bottom-width:1px;
  border-bottom-color:#d4d4d8
}

#tab-description thead th{
  vertical-align:bottom;
  padding-right:0.5714286em;
  padding-bottom:0.5714286em;
  padding-left:0.5714286em
}

#tab-description tbody tr{
  border-bottom-width:1px;
  border-bottom-color:#e4e4e7
}

#tab-description tbody tr:last-child{
  border-bottom-width:0
}

#tab-description tbody td{
  vertical-align:top;
  padding-top:0.5714286em;
  padding-right:0.5714286em;
  padding-bottom:0.5714286em;
  padding-left:0.5714286em
}

#tab-description{
  font-size:1rem;
  line-height:1.75
}

#tab-description p{
  margin-top:1.25em;
  margin-bottom:1.25em
}

#tab-description img{
  margin-top:2em;
  margin-bottom:2em
}

#tab-description video{
  margin-top:2em;
  margin-bottom:2em
}

#tab-description figure{
  margin-top:2em;
  margin-bottom:2em
}

#tab-description figure > *{
  margin-top:0;
  margin-bottom:0
}

#tab-description h2 code{
  font-size:0.875em
}

#tab-description h3 code{
  font-size:0.9em
}

#tab-description ol{
  margin-top:1.25em;
  margin-bottom:1.25em
}

#tab-description ul{
  margin-top:1.25em;
  margin-bottom:1.25em
}

#tab-description li{
  margin-top:0.5em;
  margin-bottom:0.5em
}

#tab-description > ul > li p{
  margin-top:0.75em;
  margin-bottom:0.75em
}

#tab-description > ul > li > *:first-child{
  margin-top:1.25em
}

#tab-description > ul > li > *:last-child{
  margin-bottom:1.25em
}

#tab-description > ol > li > *:first-child{
  margin-top:1.25em
}

#tab-description > ol > li > *:last-child{
  margin-bottom:1.25em
}

#tab-description ul ul, #tab-description ul ol, #tab-description ol ul, #tab-description ol ol{
  margin-top:0.75em;
  margin-bottom:0.75em
}

.prose ul ul, .prose ul ol, .prose ol ul, .prose ol ol{
  margin-top:0.75em;
  margin-bottom:0.75em;
  margin-top:0.75em;
  margin-bottom:0.75em;
  margin-top:0.75em;
  margin-bottom:0.75em
}

#tab-description hr + *{
  margin-top:0
}

#tab-description h2 + *{
  margin-top:0
}

#tab-description h3 + *{
  margin-top:0
}

#tab-description h4 + *{
  margin-top:0
}

#tab-description thead th:first-child{
  padding-left:0
}

#tab-description thead th:last-child{
  padding-right:0
}

#tab-description tbody td:first-child{
  padding-left:0
}

#tab-description tbody td:last-child{
  padding-right:0
}

#tab-description > :first-child{
  margin-top:0
}

#tab-description > :last-child{
  margin-bottom:0
}

#tab-description{
  font-size:0.875rem;
  line-height:1.7142857;
}

#tab-description p{
  margin-top:1.1428571em;
  margin-bottom:1.1428571em
}

#tab-description [class~="lead"]{
  font-size:1.2857143em;
  line-height:1.5555556;
  margin-top:0.8888889em;
  margin-bottom:0.8888889em
}

#tab-description blockquote{
  margin-top:1.3333333em;
  margin-bottom:1.3333333em;
  padding-left:1.1111111em
}

#tab-description h1{
  font-size:2.1428571em;
  margin-top:0;
  margin-bottom:0.8em;
  line-height:1.2
}

#tab-description h2{
  font-size:1.4285714em;
  margin-top:1.6em;
  margin-bottom:0.8em;
  line-height:1.4
}

#tab-description h3{
  font-size:1.2857143em;
  margin-top:1.5555556em;
  margin-bottom:0.4444444em;
  line-height:1.5555556
}

#tab-description h4{
  margin-top:1.4285714em;
  margin-bottom:0.5714286em;
  line-height:1.4285714
}

#tab-description img{
  margin-top:1.7142857em;
  margin-bottom:1.7142857em
}

#tab-description video{
  margin-top:1.7142857em;
  margin-bottom:1.7142857em
}

#tab-description figure{
  margin-top:1.7142857em;
  margin-bottom:1.7142857em
}

#tab-description figure > *{
  margin-top:0;
  margin-bottom:0
}

#tab-description figure figcaption{
  font-size:0.8571429em;
  line-height:1.3333333;
  margin-top:0.6666667em
}

#tab-description code{
  font-size:0.8571429em
}

#tab-description h2 code{
  font-size:0.9em
}

#tab-description h3 code{
  font-size:0.8888889em
}

#tab-description pre{
  font-size:0.8571429em;
  line-height:1.6666667;
  margin-top:1.6666667em;
  margin-bottom:1.6666667em;
  border-radius:0.25rem;
  padding-top:0.6666667em;
  padding-right:1em;
  padding-bottom:0.6666667em;
  padding-left:1em
}

#tab-description ol{
  margin-top:1.1428571em;
  margin-bottom:1.1428571em
}

#tab-description ul{
  margin-top:1.1428571em;
  margin-bottom:1.1428571em
}

#tab-description li{
  margin-top:0.2857143em;
  margin-bottom:0.2857143em
}

#tab-description ol > li{
  padding-left:1.5714286em
}

#tab-description ol > li::before{
  left:0
}

#tab-description ul > li{
  padding-left:1.5714286em
}

#tab-description ul > li::before{
  height:0.3571429em;
  width:0.3571429em;
  top:calc(0.8571429em - 0.1785714em);
  left:0.2142857em
}

#tab-description > ul > li p{
  margin-top:0.5714286em;
  margin-bottom:0.5714286em
}

#tab-description > ul > li > *:first-child{
  margin-top:1.1428571em
}

#tab-description > ul > li > *:last-child{
  margin-bottom:1.1428571em
}

#tab-description > ol > li > *:first-child{
  margin-top:1.1428571em
}

#tab-description > ol > li > *:last-child{
  margin-bottom:1.1428571em
}

#tab-description ul ul, #tab-description ul ol, #tab-description ol ul, #tab-description ol ol{
  margin-top:0.5714286em;
  margin-bottom:0.5714286em
}

.prose-sm ul ul, .prose-sm ul ol, .prose-sm ol ul, .prose-sm ol ol{
  margin-top:0.5714286em;
  margin-bottom:0.5714286em;
  margin-top:0.5714286em;
  margin-bottom:0.5714286em;
  margin-top:0.5714286em;
  margin-bottom:0.5714286em
}

#tab-description hr{
  margin-top:2.8571429em;
  margin-bottom:2.8571429em
}

#tab-description hr + *{
  margin-top:0
}

#tab-description h2 + *{
  margin-top:0
}

#tab-description h3 + *{
  margin-top:0
}

#tab-description h4 + *{
  margin-top:0
}

#tab-description table{
  font-size:0.8571429em;
  line-height:1.5
}

#tab-description thead th{
  padding-right:1em;
  padding-bottom:0.6666667em;
  padding-left:1em
}

#tab-description thead th:first-child{
  padding-left:0
}

#tab-description thead th:last-child{
  padding-right:0
}

#tab-description tbody td{
  padding-top:0.6666667em;
  padding-right:1em;
  padding-bottom:0.6666667em;
  padding-left:1em
}

#tab-description tbody td:first-child{
  padding-left:0
}

#tab-description tbody td:last-child{
  padding-right:0
}

#tab-description > :first-child{
  margin-top:0
}

#tab-description > :last-child{
  margin-bottom:0
}

#tab-description.prose-sm.sm\:prose{
  font-family:Lora, serif;
  font-weight:500;
  font-size:0.875rem;
  line-height:1.25rem;
  max-width:-webkit-max-content;
  max-width:-moz-max-content;
  max-width:max-content;
  font-size:1rem
}

@media screen and (max-width: 768px){
  #tab-description.prose-sm.sm\:prose{
    font-size:1rem
  }
}

.prose#tab-description.sm\:prose{
  font-family:Lora, serif;
  font-weight:500;
  font-size:0.875rem;
  line-height:1.25rem;
  max-width:-webkit-max-content;
  max-width:-moz-max-content;
  max-width:max-content;
  font-size:1rem
}

@media screen and (max-width: 768px){
  .prose#tab-description.sm\:prose{
    font-size:1rem
  }
}

#tab-description.prose-sm.sm\:prose ul li{
  padding:0px
}

.prose#tab-description.sm\:prose ul li{
  padding:0px
}

#tab-description blockquote{
  --tw-text-opacity:1 !important;
  color:rgba(16, 161, 217, var(--tw-text-opacity)) !important
}

#tab-description blockquote p, #tab-description blockquote span, #tab-description blockquote strong, #tab-description blockquote em{
  --tw-text-opacity:1 !important;
  color:rgba(16, 161, 217, var(--tw-text-opacity)) !important
}

.prose blockquote p, .prose blockquote span, .prose blockquote strong, .prose blockquote em{
  --tw-text-opacity:1 !important;
  color:rgba(16, 161, 217, var(--tw-text-opacity)) !important
}

.prose blockquote p, .prose blockquote span, .prose blockquote strong, .prose blockquote em{
  --tw-text-opacity:1 !important;
  color:rgba(16, 161, 217, var(--tw-text-opacity)) !important
}

.prose blockquote p, .prose blockquote span, .prose blockquote strong, .prose blockquote em{
  --tw-text-opacity:1 !important;
  color:rgba(16, 161, 217, var(--tw-text-opacity)) !important
}

[is=fusion-wysiwyg] #tab-description{
  max-width:100% !important;
  font-family:Lora, serif;
  font-weight:500;
  font-size:1.125rem
}

[is=fusion-wysiwyg] #tab-description ul li{
  padding:0px
}

[is=fusion-wysiwyg] #tab-description .figure{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-align:start;
      -ms-flex-align:start;
          align-items:flex-start
}

@media (min-width: 768px){
  [is=fusion-wysiwyg] #tab-description .figure{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center
  }
}

[is=fusion-child-ten] #tab-description{
  max-width:100% !important;
  font-family:Lora, serif;
  font-weight:500;
  font-size:1.125rem
}

[is=fusion-child-ten] #tab-description ul li{
  padding:0px
}

[is=fusion-child-ten] #tab-description .figure{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-align:start;
      -ms-flex-align:start;
          align-items:flex-start
}

@media (min-width: 768px){
  [is=fusion-child-ten] #tab-description .figure{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center
  }
}

del span, del bdi{
  font-size:1.125rem;
  line-height:1.75rem;
  margin-right:0.25rem;
  --tw-text-opacity:1;
  color:rgba(161, 161, 170, var(--tw-text-opacity));
  text-decoration:line-through
}

.onsale, .cart-empty{
  display:none
}

.woocommerce-notices-wrapper .woocommerce-message{
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity:1;
  border-color:rgba(97, 202, 242, var(--tw-border-opacity));
  border-width:2px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:reverse;
      -ms-flex-direction:column-reverse;
          flex-direction:column-reverse;
  -webkit-box-align:start;
      -ms-flex-align:start;
          align-items:flex-start;
  -webkit-box-pack:start;
      -ms-flex-pack:start;
          justify-content:flex-start;
  font-weight:500;
  font-size:1rem;
  line-height:1.5rem;
  margin-bottom:2.5rem;
  padding:1.25rem;
  position:fixed;
  right:0px;
  bottom:0px;
  --tw-text-opacity:1;
  color:rgba(24, 24, 27, var(--tw-text-opacity));
  z-index:20
}

@media (min-width: 1024px){
  .woocommerce-notices-wrapper .woocommerce-message{
    margin-right:2.5rem
  }
}

@media screen and (max-width: 768px){
  .woocommerce-notices-wrapper .woocommerce-message{
    font-size:1.125rem
  }
}

.woocommerce-notices-wrapper .woocommerce-message .button{
  font-weight:500;
  --tw-text-opacity:1;
  color:rgba(97, 202, 242, var(--tw-text-opacity));
  text-decoration:underline
}

.woocommerce-notices-wrapper .woocommerce-error{
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity:1;
  border-color:rgba(255, 93, 93, var(--tw-border-opacity));
  border-radius:0.375rem;
  border-width:2px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:reverse;
      -ms-flex-direction:column-reverse;
          flex-direction:column-reverse;
  -webkit-box-align:start;
      -ms-flex-align:start;
          align-items:flex-start;
  -webkit-box-pack:start;
      -ms-flex-pack:start;
          justify-content:flex-start;
  font-weight:500;
  font-size:1rem;
  line-height:1.5rem;
  margin-bottom:2.5rem;
  padding:1.25rem;
  position:fixed;
  right:0px;
  bottom:0px;
  --tw-text-opacity:1;
  color:rgba(97, 202, 242, var(--tw-text-opacity));
  z-index:20
}

@media (min-width: 1024px){
  .woocommerce-notices-wrapper .woocommerce-error{
    margin-right:2.5rem
  }
}

@media screen and (max-width: 768px){
  .woocommerce-notices-wrapper .woocommerce-error{
    font-size:1.125rem
  }
}

.woocommerce-notices-wrapper .woocommerce-error li strong{
  --tw-text-opacity:1;
  color:rgba(255, 93, 93, var(--tw-text-opacity))
}

.woocommerce-notices-wrapper .woocommerce-error .button{
  font-weight:500;
  --tw-text-opacity:1;
  color:rgba(97, 202, 242, var(--tw-text-opacity));
  text-decoration:underline
}

.checkout-field label{
  font-weight:700;
  --tw-text-opacity:1;
  color:rgba(82, 82, 91, var(--tw-text-opacity))
}

.checkout-field label.screen-reader-text{
  -webkit-clip-path:none;
          clip-path:none;
  height:100% !important;
  margin-left:0px !important;
  margin-right:0px !important;
  position:relative !important;
  width:100% !important
}

.checkout-field span.select2{
  --tw-border-opacity:1;
  border-color:rgba(228, 228, 231, var(--tw-border-opacity))
}

.checkout-field span.select2:focus{
  --tw-border-opacity:1;
  border-color:rgba(147, 197, 253, var(--tw-border-opacity))
}

.checkout-field span.select2{
  border-radius:0.375rem;
  border-width:1px;
  display:block;
  margin-top:1rem;
  padding-top:1rem;
  padding-bottom:1rem;
  padding-left:2rem;
  padding-right:2rem
}

.checkout-field span.select2:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(147, 197, 253, var(--tw-ring-opacity))
}

.checkout-field span.select2{
  width:100%
}

.checkout-field span.select2 .select2-selection--single{
  border-width:0px !important
}

.woocommerce-cart .woocommerce-shipping-totals{
  display:none
}

mark{
  --tw-bg-opacity:1;
  background-color:rgba(147, 197, 253, var(--tw-bg-opacity));
  --tw-bg-opacity:0.7
}

abbr[title]{
  text-decoration:none !important
}

.woocommerce-password-strength{
  font-size:0.875rem;
  line-height:1.25rem;
  margin-top:0.5rem;
  font-style:italic;
  font-size:1rem
}

@media screen and (max-width: 768px){
  .woocommerce-password-strength{
    font-size:1rem
  }
}

.woocommerce-password-strength.short{
  --tw-text-opacity:1;
  color:rgba(248, 113, 113, var(--tw-text-opacity))
}

.woocommerce-password-strength.bad{
  --tw-text-opacity:1;
  color:rgba(251, 146, 60, var(--tw-text-opacity))
}

.woocommerce-password-strength.good{
  --tw-text-opacity:1;
  color:rgba(250, 204, 21, var(--tw-text-opacity))
}

.woocommerce-password-strength.strong{
  --tw-text-opacity:1;
  color:rgba(74, 222, 128, var(--tw-text-opacity))
}

.woocommerce-breadcrumb{
  margin-bottom:2rem;
  --tw-text-opacity:1;
  color:rgba(82, 82, 91, var(--tw-text-opacity))
}

.recurring-totals{
  margin-top:1rem
}

.wc_payment_method label[for=payment_method_stripe_sepa], .wc_payment_method label[for=payment_method_stripe]{
  font-weight:700;
  font-size:1.125rem;
  line-height:1.75rem;
  --tw-text-opacity:1;
  color:rgba(0, 0, 0, var(--tw-text-opacity))
}

.wc_payment_method label[for=payment_method_stripe_sepa] img, .wc_payment_method label[for=payment_method_stripe] img{
  display:none
}

.wc_payment_method .payment_box.payment_method_stripe_sepa p, .wc_payment_method .payment_box.payment_method_stripe p{
  --tw-text-opacity:1;
  color:rgba(0, 0, 0, var(--tw-text-opacity))
}

.wc_payment_method .payment_box.payment_method_stripe_sepa p.wc-stripe-sepa-mandate, .wc_payment_method .payment_box.payment_method_stripe p.wc-stripe-sepa-mandate{
  font-size:0.875rem;
  line-height:1.25rem
}

.wc_payment_method .payment_box.payment_method_stripe_sepa p.wc-stripe-sepa-mandate,.wc_payment_method .payment_box.payment_method_stripe p.wc-stripe-sepa-mandate{
  font-size:1rem
}

@media screen and (max-width: 768px){
  .wc_payment_method .payment_box.payment_method_stripe_sepa p.wc-stripe-sepa-mandate,.wc_payment_method .payment_box.payment_method_stripe p.wc-stripe-sepa-mandate{
    font-size:1rem
  }
}

.woocommerce-checkout-review-order-table .recurring-totals, .woocommerce-checkout-review-order-table .recurring-total{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
  margin-bottom:0.5rem
}

.woocommerce-privacy-policy-text{
  font-size:0.875rem;
  line-height:1.25rem;
  --tw-text-opacity:1;
  color:rgba(0, 0, 0, var(--tw-text-opacity));
  font-size:1rem
}

@media screen and (max-width: 768px){
  .woocommerce-privacy-policy-text{
    font-size:1rem
  }
}

.wc-credit-card-form label{
  --tw-text-opacity:1;
  color:rgba(0, 0, 0, var(--tw-text-opacity))
}

label[for=card-element]{
  opacity:0
}

.wc-stripe-elements-field, .wc-stripe-iban-element-field{
  --tw-bg-opacity:1 !important;
  background-color:rgba(228, 228, 231, var(--tw-bg-opacity)) !important;
  --tw-bg-opacity:0.4 !important;
  --tw-border-opacity:1 !important;
  border-color:rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  border-radius:0.375rem !important;
  border-width:2px !important;
  padding:1rem !important
}

.ElementsApp .InputElement::-webkit-input-placeholder{
  color:#a1a1aa !important
}

.ElementsApp .InputElement+.InputContainer-placeholder--ie,.ElementsApp .InputElement::-webkit-input-placeholder,.ElementsApp .InputElement::-moz-placeholder,.ElementsApp .InputElement:-ms-input-placeholder,.ElementsApp .InputElement::placeholder{
  color:#a1a1aa !important
}

html[lang=en-US] .restrictfrench{
  display:none
}

html[lang=en-US] .restrictenglish{
  margin-top:1rem;
  margin-bottom:1rem;
  padding-left:1rem;
  padding-right:1rem
}

html[lang=fr-FR] .restrictenglish{
  display:none
}

html[lang=fr-FR] .restrictfrench{
  margin-top:1rem;
  margin-bottom:1rem;
  padding-left:1rem;
  padding-right:1rem
}

[is=fusion-block-newsletter] .copyright{
  text-decoration:underline
}

[is=fusion-block-newsletter] .wysiwyg p{
  line-height:1.625;
  --tw-text-opacity:1;
  color:rgba(63, 63, 70, var(--tw-text-opacity))
}

[is=fusion-block-newsletter] .title{
  font-weight:600;
  font-size:1.125rem;
  line-height:1.75rem;
  padding-bottom:1.5rem;
  text-align:center;
  --tw-text-opacity:1;
  color:rgba(0, 0, 0, var(--tw-text-opacity))
}

@media (min-width: 1024px){
  [is=fusion-block-newsletter] .title{
    font-size:1.25rem;
    line-height:1.75rem
  }
}

@media (min-width: 1280px){
  [is=fusion-block-newsletter] .title{
    font-size:1.5rem;
    line-height:2rem
  }
}

[is=fusion-block-newsletter] .terms{
  font-size:0.75rem;
  line-height:1rem;
  line-height:1.25;
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  text-align:center;
  --tw-text-opacity:1;
  color:rgba(161, 161, 170, var(--tw-text-opacity))
}

[is=fusion-block-newsletter] .terms a{
  --tw-text-opacity:1;
  color:rgba(16, 161, 217, var(--tw-text-opacity));
  text-decoration:underline
}

[is=fusion-block-newsletter] .info{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column
}

[is=fusion-block-newsletter] .info span{
  width:100%
}

[is=fusion-block-newsletter] .info span .wpcf7-not-valid-tip{
  display:none
}

[is=fusion-block-newsletter] .info .ajax-loader{
  display:none
}

[is=fusion-block-newsletter] .info #email{
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity:1;
  border-color:rgba(212, 212, 216, var(--tw-border-opacity))
}

[is=fusion-block-newsletter] .info #email:focus{
  --tw-border-opacity:1;
  border-color:rgba(16, 161, 217, var(--tw-border-opacity))
}

[is=fusion-block-newsletter] .info #email{
  border-radius:0.375rem;
  border-width:1px
}

[is=fusion-block-newsletter] .info #email:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

[is=fusion-block-newsletter] .info #email{
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  padding-left:1rem;
  padding-right:1rem;
  width:100%;
  -webkit-transition-property:background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform;
  transition-property:background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform;
  transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-box-shadow, -webkit-transform;
  -webkit-transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration:150ms;
          transition-duration:150ms;
  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration:300ms;
          transition-duration:300ms;
  -webkit-appearance:none
}

[is=fusion-block-newsletter] .info #submit{
  --tw-bg-opacity:1;
  background-color:rgba(16, 161, 217, var(--tw-bg-opacity));
  --tw-border-opacity:1;
  border-color:rgba(212, 212, 216, var(--tw-border-opacity));
  border-radius:0.375rem;
  cursor:pointer;
  font-weight:700;
  margin-top:1rem;
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  padding-left:0.5rem;
  padding-right:0.5rem;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity));
  text-transform:uppercase;
  width:100%
}

[is=fusion-block-newsletter] .wpcf7 form.invalid>.wpcf7-response-output{
  background-color:#dc2626;
  border-color:#dc2626 !important;
  color:#fff;
  padding:.5rem 1rem .5rem 1rem !important;
  border-radius:0.5rem;
  border-style:none;
  font-weight:500;
  font-size:0.875rem;
  line-height:1.25rem;
  margin:1rem;
  position:absolute;
  right:0px;
  bottom:0px;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity));
  z-index:60
}

@media (min-width: 1024px){
  [is=fusion-block-newsletter] .wpcf7 form.invalid>.wpcf7-response-output{
    font-size:1rem;
    line-height:1.5rem
  }
}

[is=fusion-block-newsletter] .wpcf7 form.invalid>.wpcf7-response-output{
  font-size:1rem
}

@media screen and (max-width: 768px){
  [is=fusion-block-newsletter] .wpcf7 form.invalid>.wpcf7-response-output{
    font-size:1rem
  }
}

[is=fusion-block-newsletter] .wpcf7 form.invalid>.wpcf7-response-output{
  -webkit-animation:cssAnimation 5s forwards;
  animation:cssAnimation 5s forwards;
  -webkit-backdrop-filter:blur(7px);
          backdrop-filter:blur(7px)
}

[is=fusion-block-newsletter] .wpcf7-response-output,[is=fusion-block-newsletter] .wpcf7 form.unaccepted{
  background-color:#ea580c;
  border-color:#ea580c !important;
  color:#fff;
  padding:.5rem 1rem .5rem 1rem !important
}

[is=fusion-block-newsletter] .wpcf7-response-output, [is=fusion-block-newsletter] .wpcf7 form.unaccepted{
  border-radius:0.5rem;
  border-style:none;
  font-weight:500;
  font-size:0.875rem;
  line-height:1.25rem;
  margin:1rem;
  position:absolute;
  right:0px;
  bottom:0px;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity));
  z-index:60
}

@media (min-width: 1024px){
  [is=fusion-block-newsletter] .wpcf7-response-output, [is=fusion-block-newsletter] .wpcf7 form.unaccepted{
    font-size:1rem;
    line-height:1.5rem
  }
}

[is=fusion-block-newsletter] .wpcf7-response-output,[is=fusion-block-newsletter] .wpcf7 form.unaccepted{
  font-size:1rem
}

@media screen and (max-width: 768px){
  [is=fusion-block-newsletter] .wpcf7-response-output,[is=fusion-block-newsletter] .wpcf7 form.unaccepted{
    font-size:1rem
  }
}

[is=fusion-block-newsletter] .wpcf7-response-output,[is=fusion-block-newsletter] .wpcf7 form.unaccepted{
  -webkit-animation:cssAnimation 5s forwards;
  animation:cssAnimation 5s forwards;
  -webkit-backdrop-filter:blur(7px);
          backdrop-filter:blur(7px)
}

@keyframes cssAnimation{
  0%{
    opacity:1
  }

  90%{
    opacity:1
  }

  100%{
    opacity:0
  }
}

@-webkit-keyframes cssAnimation{
  0%{
    opacity:1
  }

  90%{
    opacity:1
  }

  100%{
    opacity:0
  }
}

[is=fusion-block-post-info] .avatar{
  height:100%;
  -o-object-fit:cover;
     object-fit:cover;
  width:100%
}

[is=fusion-block-post-share] .copied-alert{
  display:none;
  width:200px;
  position:absolute;
  top:-39px;
  left:-5px;
  color:#000
}

[is=fusion-block-single-header] .video-player iframe{
  height:100%;
  width:100%
}

[is=fusion-grid-authors-listing] .link{
  --tw-text-opacity:1;
  color:rgba(16, 161, 217, var(--tw-text-opacity));
  text-decoration:underline
}

[is=fusion-grid-authors-listing] .contact{
  font-weight:500;
  margin-top:0.5rem
}

[is=fusion-grid-authors-listing] .contact-item{
  --tw-text-opacity:1;
  color:rgba(16, 161, 217, var(--tw-text-opacity));
  text-transform:uppercase
}

[is=fusion-grid-authors-listing] .avatar{
  height:100%;
  -o-object-fit:cover;
     object-fit:cover;
  width:100%
}

[is=fusion-navigation-footer]{
  padding-top:125px
}

@media(max-width: 1024x){
  [is=fusion-navigation-footer]{
    padding-top:50px
  }
}

[is=fusion-navigation-footer] .is--navigation-custom-rounded{
  height:100%;
  width:100%;
  top:calc(-100% + 75px)
}

[is=fusion-navigation-footer] .copyright{
  text-decoration:underline
}

[is=fusion-navigation-footer] .wysiwyg p{
  line-height:1.625;
  --tw-text-opacity:1;
  color:rgba(63, 63, 70, var(--tw-text-opacity))
}

[is=fusion-navigation-footer] .wpml-ls-legacy-dropdown{
  margin-top:1rem
}

[is=fusion-navigation-footer] .wpml-ls-legacy-dropdown a.wpml-ls-item-toggle{
  border-radius:0.375rem;
  padding:1rem
}

[is=fusion-navigation-footer] .title{
  font-weight:600;
  font-size:1.125rem;
  line-height:1.75rem;
  padding-bottom:1.5rem;
  --tw-text-opacity:1;
  color:rgba(0, 0, 0, var(--tw-text-opacity))
}

@media (min-width: 640px){
  [is=fusion-navigation-footer] .title{
    font-size:1.25rem;
    line-height:1.75rem
  }
}

@media (min-width: 1024px){
  [is=fusion-navigation-footer] .title{
    font-size:1.5rem;
    line-height:2rem
  }
}

@media (min-width: 1280px){
  [is=fusion-navigation-footer] .title{
    font-size:1.5rem;
    line-height:2rem
  }
}

[is=fusion-navigation-footer] .terms{
  font-size:0.75rem;
  line-height:1rem;
  line-height:1.25;
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  --tw-text-opacity:1;
  color:rgba(161, 161, 170, var(--tw-text-opacity))
}

[is=fusion-navigation-footer] .terms a{
  text-decoration:underline
}

[is=fusion-navigation-footer] .info{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
      flex-wrap:wrap;
  -webkit-box-pack:start;
      -ms-flex-pack:start;
          justify-content:flex-start
}

[is=fusion-navigation-footer] .info span{
  width:100%
}

@media (min-width: 768px){
  [is=fusion-navigation-footer] .info span{
    width:66.666667%
  }
}

[is=fusion-navigation-footer] .info span .wpcf7-not-valid-tip{
  display:none
}

[is=fusion-navigation-footer] .info .ajax-loader{
  display:none
}

[is=fusion-navigation-footer] .info #email{
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity:1;
  border-color:rgba(212, 212, 216, var(--tw-border-opacity))
}

[is=fusion-navigation-footer] .info #email:focus{
  --tw-border-opacity:1;
  border-color:rgba(16, 161, 217, var(--tw-border-opacity))
}

[is=fusion-navigation-footer] .info #email{
  border-radius:0.375rem;
  border-width:1px
}

[is=fusion-navigation-footer] .info #email:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

[is=fusion-navigation-footer] .info #email{
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  padding-left:1rem;
  padding-right:1rem;
  width:100%;
  -webkit-transition-property:background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform;
  transition-property:background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform;
  transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-box-shadow, -webkit-transform;
  -webkit-transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration:150ms;
          transition-duration:150ms;
  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration:300ms;
          transition-duration:300ms
}

@media (min-width: 768px){
  [is=fusion-navigation-footer] .info #email{
    border-top-right-radius:0px;
    border-bottom-right-radius:0px;
    border-top-left-radius:0.375rem;
    border-bottom-left-radius:0.375rem
  }
}

[is=fusion-navigation-footer] .info #email{
  -webkit-appearance:none
}

[is=fusion-navigation-footer] .info #submit{
  --tw-bg-opacity:1;
  background-color:rgba(16, 161, 217, var(--tw-bg-opacity));
  --tw-border-opacity:1;
  border-color:rgba(212, 212, 216, var(--tw-border-opacity));
  border-radius:0.375rem;
  cursor:pointer;
  font-weight:500;
  margin-top:0.5rem;
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  padding-left:0.5rem;
  padding-right:0.5rem;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity));
  width:100%
}

@media (min-width: 768px){
  [is=fusion-navigation-footer] .info #submit{
    border-top-left-radius:0px;
    border-bottom-left-radius:0px;
    border-top-right-radius:0.375rem;
    border-bottom-right-radius:0.375rem;
    margin-top:0px;
    width:33.333333%
  }
}

[is=fusion-navigation-footer] .wpcf7 form.invalid>.wpcf7-response-output{
  background-color:#dc2626;
  border-color:#dc2626 !important;
  color:#fff;
  padding:.5rem 1rem .5rem 1rem !important;
  border-radius:0.5rem;
  border-style:none;
  font-weight:500;
  font-size:0.875rem;
  line-height:1.25rem;
  margin:1rem;
  position:absolute;
  right:0px;
  bottom:0px;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity));
  z-index:60
}

@media (min-width: 1024px){
  [is=fusion-navigation-footer] .wpcf7 form.invalid>.wpcf7-response-output{
    font-size:1rem;
    line-height:1.5rem
  }
}

[is=fusion-navigation-footer] .wpcf7 form.invalid>.wpcf7-response-output{
  font-size:1rem
}

@media screen and (max-width: 768px){
  [is=fusion-navigation-footer] .wpcf7 form.invalid>.wpcf7-response-output{
    font-size:1rem
  }
}

[is=fusion-navigation-footer] .wpcf7 form.invalid>.wpcf7-response-output{
  -webkit-animation:cssAnimation 5s forwards;
  animation:cssAnimation 5s forwards;
  -webkit-backdrop-filter:blur(7px);
          backdrop-filter:blur(7px)
}

[is=fusion-navigation-footer] .wpcf7-response-output,[is=fusion-navigation-footer] .wpcf7 form.unaccepted{
  background-color:#ea580c;
  border-color:#ea580c !important;
  color:#fff;
  padding:.5rem 1rem .5rem 1rem !important
}

[is=fusion-navigation-footer] .wpcf7-response-output, [is=fusion-navigation-footer] .wpcf7 form.unaccepted{
  border-radius:0.5rem;
  border-style:none;
  font-weight:500;
  font-size:0.875rem;
  line-height:1.25rem;
  margin:1rem;
  position:absolute;
  right:0px;
  bottom:0px;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity));
  z-index:60
}

@media (min-width: 1024px){
  [is=fusion-navigation-footer] .wpcf7-response-output, [is=fusion-navigation-footer] .wpcf7 form.unaccepted{
    font-size:1rem;
    line-height:1.5rem
  }
}

[is=fusion-navigation-footer] .wpcf7-response-output,[is=fusion-navigation-footer] .wpcf7 form.unaccepted{
  font-size:1rem
}

@media screen and (max-width: 768px){
  [is=fusion-navigation-footer] .wpcf7-response-output,[is=fusion-navigation-footer] .wpcf7 form.unaccepted{
    font-size:1rem
  }
}

[is=fusion-navigation-footer] .wpcf7-response-output,[is=fusion-navigation-footer] .wpcf7 form.unaccepted{
  -webkit-animation:cssAnimation 5s forwards;
  animation:cssAnimation 5s forwards;
  -webkit-backdrop-filter:blur(7px);
          backdrop-filter:blur(7px)
}

@keyframes cssAnimation{
  0%{
    opacity:1
  }

  90%{
    opacity:1
  }

  100%{
    opacity:0
  }
}

@-webkit-keyframes cssAnimation{
  0%{
    opacity:1
  }

  90%{
    opacity:1
  }

  100%{
    opacity:0
  }
}

[is=fusion-navigation-main-child]{
  display:unset
}

@media(max-width: 1024px){
  [is=fusion-navigation-main-child]{
    display:block
  }
}

[is=fusion-navigation-main-child] .nav a.nav-item:last-child{
  --tw-text-opacity:1;
  color:rgba(16, 161, 217, var(--tw-text-opacity))
}

.is--sticky{
  position:sticky;
  top:0px
}

.is--sticky #header-logo{
  display:none
}

[is=grid-posts-choices] .leading-tight-important{
  line-height:1.25 !important
}

[is=grid-posts-choices] .video-player iframe{
  height:100%;
  width:100%
}

[is=grid-posts-choices] .swiper-button-prev,[is=grid-posts-choices] .swiper-button-next{
  background:#fff;
  border-radius:999px;
  width:35px;
  height:35px
}

[is=grid-posts-choices] .swiper-button-next:after,[is=grid-posts-choices] .swiper-button-prev:after{
  font-size:18px;
  color:#10a1d9;
  font-weight:600
}

[is=grid-posts-choices] .swiper-button-prev-2,[is=grid-posts-choices] .swiper-button-next-2{
  background:#fff;
  border-radius:999px;
  width:35px;
  height:35px
}

[is=grid-posts-choices] .swiper-button-next-2:after,[is=grid-posts-choices] .swiper-button-prev-2:after{
  font-size:18px;
  color:#10a1d9;
  font-weight:600
}

[is=grid-new-video-latest] .video-player iframe{
  height:100%;
  width:100%
}

[is=grid-posts-news] .leading-tight-important{
  line-height:1.25 !important
}

[is=grid-posts-news] .video-player iframe{
  height:100%;
  width:100%
}

[is=grid-posts-news] .swiper-button-prev,[is=grid-posts-news] .swiper-button-next{
  background:#fff;
  border-radius:999px;
  width:35px;
  height:35px
}

[is=grid-posts-news] .swiper-button-next:after,[is=grid-posts-news] .swiper-button-prev:after{
  font-size:18px;
  color:#10a1d9;
  font-weight:600
}

[is=grid-posts-news] .swiper-button-prev-2,[is=grid-posts-news] .swiper-button-next-2{
  background:#fff;
  border-radius:999px;
  width:35px;
  height:35px
}

[is=grid-posts-news] .swiper-button-next-2:after,[is=grid-posts-news] .swiper-button-prev-2:after{
  font-size:18px;
  color:#10a1d9;
  font-weight:600
}

[is=fusion-wysiwyg] .prose{
  max-width:100% !important;
  font-family:Lora, serif;
  font-weight:500;
  font-size:1.125rem !important
}

[is=fusion-wysiwyg] .prose ul li{
  padding:0px
}

[is=fusion-wysiwyg] .prose .figure{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-align:start;
      -ms-flex-align:start;
          align-items:flex-start
}

@media (min-width: 768px){
  [is=fusion-wysiwyg] .prose .figure{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center
  }
}

[is=fusion-wysiwyg] .sm\:prose ul>li::before{
  content:none
}

[is=fusion-wysiwyg] p:before{
  content:none
}

[is=fusion-child-ten] .video-player iframe{
  width:100%
}

[is=fusion-child-ten] .prose{
  max-width:100% !important;
  font-family:Lora, serif;
  font-weight:500;
  font-size:1.125rem !important
}

[is=fusion-child-ten] .prose ul li{
  padding:0px
}

[is=fusion-child-ten] .prose .figure{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-align:start;
      -ms-flex-align:start;
          align-items:flex-start
}

@media (min-width: 768px){
  [is=fusion-child-ten] .prose .figure{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center
  }
}

[is=fusion-child-ten] .sm\:prose ul>li::before{
  content:none
}

[is=fusion-child-ten] p:before{
  content:none
}

[is=fusion-child-nine] .video-player iframe{
  width:100%
}